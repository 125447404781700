import React, { Component } from "react"
import { Link } from "gatsby"
import styled from "styled-components"

class NewsPreview extends Component {
  render() {
    const { news } = this.props
    const Wrapper = styled.div`
      .news-item {
        flex: 1 0 17rem;
        margin: 1rem;

        overflow: hidden;
        max-height: 250px;
        border-bottom: 1px solid #707070;
        padding: 40px 0 10px;
        a {
          text-decoration: none;
        }
      }

      .news-date {
        display: flex;
        color: #128c7e;
        font-size: 16px;
      }
      .news-title {
        color: #128c7e;
      }
    `

    return (
      <Wrapper>
        <div className="news-item">
          <Link to={`/${news.slug}`}>
            <p className="news-date">{news.date}</p>
            <h2 className="news-title text-left">{news.title}</h2>
          </Link>
        </div>
      </Wrapper>
    )
  }
}

export default NewsPreview
