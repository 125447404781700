import React from "react"
import { graphql } from "gatsby"
import Helmet from "react-helmet"
import Layout from "../components/layout"

// import SEO from "../components/seo"
import Img from "gatsby-image"
import BlogPreviewComponent from "../components/BlogPreviewComponent"
import ProductPreview from "../components/ProductPreview"
import NewsPreview from "../components/NewsPreview"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faHeart, faComment } from "@fortawesome/free-solid-svg-icons"
import { Carousel } from "react-bootstrap"
import styled from "styled-components"
import media from "styled-media-query"

class IndexPage extends React.Component {
  render() {
    const siteTitle = this.props.data.site.siteMetadata.title
    // const portfolioImages = this.props.data.allContentfulPortfolio.edges
    const blog = this.props.data.allContentfulPortfolio.edges
    const products = this.props.data.allContentfulProduct.edges

    // const instaimg = this.props.data.allInstaNode.edges
    const coverphoto = this.props.data.coverImg
    const news = this.props.data.allContentfulNews.edges

    const Wrapper = styled.div`
      ${media.lessThan("small")`
      .carousel-item {
        height: 80vh;
      }
  `}
    `

    return (
      <Layout>
        <Helmet title={siteTitle} />
        <Wrapper>
          <div className="herosection">
            <div
              className="cover animated"
              style={{
                position: "relative",
                left: 0,
                top: 0,
                width: "100%",
                height: "100%",
              }}
            >
              {/* <Img
              title="Cover image"
              alt="Air Balloon in Nature"
              fluid={this.props.data.slide2.childImageSharp.fluid}
              style={{
                position: "relative",
                left: 0,
                top: 0,
                width: "100%",
                height: "100%",
              }}
            /> */}
              <Carousel>
                <Carousel.Item>
                  <Img
                    title="slide1"
                    alt="forest"
                    fluid={this.props.data.slide1.childImageSharp.fluid}
                    className="cover animated"
                    style={{
                      position: "relative",
                      left: 0,
                      top: 0,
                      width: "100%",
                      height: "100%",
                    }}
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <Img
                    title="slide2"
                    alt="heyhey"
                    fluid={this.props.data.slide2.childImageSharp.fluid}
                    className="cover animated"
                    style={{
                      position: "relative",
                      left: 0,
                      top: 0,
                      width: "100%",
                      height: "100%",
                    }}
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <Img
                    title="slide3"
                    alt="heyhey"
                    fluid={this.props.data.slide3.childImageSharp.fluid}
                    className="cover animated"
                    style={{
                      position: "relative",
                      left: 0,
                      top: 0,
                      width: "100%",
                      height: "100%",
                    }}
                  />
                </Carousel.Item>
              </Carousel>

              <div className="overlay">
                {/* <div className="center">
                <h1 className="name">
                  <b>ここに何かコピーライティング</b>
                </h1>
                <h5 className="greetings">キャッチコピー</h5> 
              </div> */}
              </div>
            </div>

            <div className="indexpage">
              <div className="right-section blog-post pad-70">
                <div className="container">
                  <div className="col-md-8 offset-md-2 portfolio-blk-container text-center ">
                    <h2 className="section-title">NEWS</h2>
                    <div className="news-container">
                      {news.map(({ node }) => {
                        return (
                          <div key={node.id} className="col-md-12">
                            <NewsPreview news={node} />
                          </div>
                        )
                      })}
                    </div>
                  </div>
                  <div className="pad-70">
                    <h2 className="portfolio-title text-center section-title">
                      最新のブログ
                    </h2>
                    <div className="col-md-10 offset-md-1 ">
                      <div className="row">
                        {blog.map(({ node }) => {
                          return (
                            <div key={node.id} className="col-md-6">
                              <BlogPreviewComponent blog={node} />
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  </div>
                  <div className="pad-70">
                    <h2 className="portfolio-title text-center  section-title">
                      商品一覧
                    </h2>
                    <div className="col-md-10 offset-md-1 ">
                      <div className="row">
                        {products.map(({ node }) => {
                          return (
                            <div key={node.id} className="col-md-6">
                              <ProductPreview product={node} />
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  </div>

                  {/* <div className="pad-70">
                  <h2 className="portfolio-title text-center  section-title">
                    Instagram Feed
                  </h2>
                  <div className="col-md-10 offset-md-1 ">
                    <ul className="row insta-blk">
                      {instaimg.map(({ node }) => {
                        return (
                          <li key={node.id} className="insta-img-cnt">
                            <Img fixed={node.localFile.childImageSharp.fixed} />
                            <p className="insta-likes-comm">
                              {" "}
                              <span className="insta-like">
                                {" "}
                                <FontAwesomeIcon
                                  icon={faHeart}
                                  className="icon"
                                />
                                {node.likes}
                              </span>{" "}
                              <span className="insta-comment">
                                <FontAwesomeIcon
                                  className="icon"
                                  icon={faComment}
                                />
                                {node.comments}
                              </span>
                            </p>
                          </li>
                        )
                      })}
                    </ul>
                    <div className=""><Link>Follow me on Instagram</Link></div> 
                  </div>
                </div>*/}
                </div>
              </div>
            </div>
          </div>
        </Wrapper>
      </Layout>
    )
  }
}

export default IndexPage

export const query = graphql`
  query allImgsQuery {
    site {
      siteMetadata {
        title
        description
      }
    }
    slide1: file(relativePath: { eq: "slide1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920, maxHeight: 1080) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    slide2: file(relativePath: { eq: "slide2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920, maxHeight: 1080) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    slide3: file(relativePath: { eq: "slide3.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920, maxHeight: 1080) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    allContentfulNews (limit: 5) {
      edges {
        node {
          id
          title
          slug
          description {
            description
          }
          date(formatString: "MMMM Do, YYYY")
        }
      }
    }
    allContentfulPortfolio(limit: 4) {
      edges {
        node {
          author
          createdAt(formatString: "MMMM DD, YYYY")
          id
          blogTitle
          blogDescription {
            blogDescription
          }
          slug
          blogImage {
            file {
              url
            }
            fluid(maxWidth: 1800) {
              ...GatsbyContentfulFluid_noBase64
            }
          }
        }
      }
    }
    allContentfulProduct {
      edges {
        node {
          thumnail {
            file {
              url
            }
            fluid(maxWidth: 1800) {
              ...GatsbyContentfulFluid_noBase64
            }
          }

          slug
          name
          description {
            description
          }
        }
      }
    }
    # allInstaNode(limit: 8) {
    #   edges {
    #     node {
    #       id
    #       likes
    #       comments
    #       mediaType
    #       preview
    #       original
    #       timestamp
    #       caption
    #       localFile {
    #         childImageSharp {
    #           fixed(width: 200, height: 200) {
    #             ...GatsbyImageSharpFixed
    #           }
    #         }
    #       }
    #     }
    #   }
    # }
  }
`
